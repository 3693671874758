import React, {useEffect} from "react";
import { Container, Row, Col } from "reactstrap";
import "./features.css";

const FeatureData = [
  {
    title: "Corporate Training Program",
    desc: "The highly sought-after Campus to Corporate Training Program not only equips recruits with essential technical skills but also instills the ability to comprehend and address techno-functional project requirements while effectively communicating with onsite teams, ensuring their immediate impact and value in the professional sphere.",
    icon: "ri-draft-line",

  },

  {
    title: "Placement Support",
    desc: "At Expertzlab, we provide customized technology training solutions by closely collaborating with organizations' Project Management Offices (PMO) or Technology teams to define tailored syllabi and training requirements, facilitating the selection and recruitment of trainees through campus recruitment programs.",
    icon: "ri-discuss-line",
  },

  {
    title: "Certification",
    desc: "Our TCCA certification signifies our commitment to delivering high-quality education and training programs that align with industry standards and best practices. This certification is a testament to our dedication to providing students with the knowledge and skills they need to excel in their chosen fields.",
    icon: "ri-contacts-book-line",
  },
];

const Features = () => {

  useEffect(() => {
    
    const customFonts = document.querySelectorAll(".custom-font");
    let maxHeight = 0;

    customFonts.forEach((font) => {
      const height = font.scrollHeight;
      maxHeight = Math.max(maxHeight, height);
    });

    customFonts.forEach((font) => {
      font.style.height = `${maxHeight}px`;
    });
  }, []);

  return (
    <section>
      <Container>
        <Row>
          {FeatureData.map((item, index) => (
            <Col  lg="4" md="6" key={index}> 
              <div className="single__feature text-center px-4 font-size-lg">
                <h2 className="mb-3">
                  <i class={item.icon}></i>
                </h2>
                <h6 style={{fontSize:'large'}}>{item.title}</h6>
                <p class="custom-font">{item.desc}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Features;
