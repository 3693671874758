import React from "react";
 import "./all-placement.css";
import { Container, Row, Col } from "reactstrap";
import PlacebgImg from "../../assests/images/placem.png"
import { Link } from 'react-router-dom';




const PlaceMent = () => {

  <style>
  @import url('https://fonts.googleapis.com/css2?family=Alegreya&family=Lora:wght@600&family=Oswald:wght@300;400&display=swap');
</style>
  
  return (
    <div>
    <section style={{backgroundColor: '#fafafa',borderRadius: '50px 90px 50px 50px',marginBottom:'60px'}}>
     
     
    
        <Container >
        


    <div className="wrapper1">
      <Row>
        <Col md={12} lg={6}>
         
          <div className="place-info">
          
            <h1 style={{fontWeight:'bold'}}>
            We provide placement assistance for all of our Courses.
            </h1>
            <p style={{maxWidth:'600px'}}>
            We offer comprehensive training programs tailored to organizational needs, ensuring recruits are ready to contribute from day one, with additional support through placement assistance for all our courses.
            </p>
            <div className="w-100" style={{ display: 'flex', alignItems: 'center',justifyContent:'start',maxWidth:'350px',marginLeft:'3%',marginTop:'10px' }} >

<Link to="/all-courses">
<button className="btnn">Our Courses</button>
</Link>

<Link to="/contact">
    <button className="btin">Contact US</button>
 </Link>
 
</div>
          </div>
         
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={PlacebgImg} alt="hero" style={{width:'100%'}} />
          </div>
        </Col>
      </Row>
    </div>
  

                <br/>
   
            </Container>
            
       
       
    </section>

     <Row>
     <Col >
     <div className="text-wrapper">
                  <h2 >
                  We Provide Assured Placements...
                  </h2></div>
                <br/>
 
 
 <p  style={{ padding:'30px 10%', fontWeight:'300',fontStyle:'Normal', fontSize: '18px',marginRight:'20px',textAlign:'center' }}> 
 At Expertzlab, we specialize in customized technology training for organizations, collaborating closely with PMOs or Technology teams. Our unique approach, including flexible candidate selection and a rigorous 2-3 month training program, ensures recruits make substantial contributions from day one. With a highly sought-after Campus to Corporate Training Program, our adaptable onboarding supports expertise in nearly 40 technologies, aligning closely with organizational needs.

  
  



  
  </p>

 

  </Col>

  

  </Row>
  </div>

  );
};

export default PlaceMent;
