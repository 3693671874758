import React from "react";
import "./Mearn-stack.css";
import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import MearnImg from '../../assests/images/MEA.png';



const MearnStc = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section >
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{  fontWeight:'bold',fontStyle:'Normal', marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          MEA(R)N STACK TRAINING
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 10th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={MearnImg} alt="Big Data" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is MEA(R)N Stack? </h3>

            <p style={{fontStyle:'normal'}}>
            The MEAN or MERN Stack is a JavaScript-based full-stack development platform widely used by over 70% of software development 
            companies. JavaScript, currently the most adopted and utilized computer programming language, forms the core of this stack. 
            Typically, a web application consists of three tiers: Front-End, Middleware, and Persistence Tier. Traditionally, views in 
            web applications were generated by server-side logic, leading to resource waste and response delays when generating the same 
            view for multiple users. To address this, it's more efficient to pre-construct views and serve them from static web servers 
            or CDN networks, reducing the load on the application. 
            The MEAN Stack comprises MongoDB, Express, Angular, and Node.js, combining these technologies for robust web development.
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Javascript and Web Design (1 Month)<br/></h6>
             Learn web design essentials with HTML, CSS3, and Bootstrap, then delve into interactive elements with JavaScript and advanced 
             algorithms for a month, honing skills through practical projects. 
             This comprehensive curriculum equips you with key technologies for modern web development.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Typescript and Object Oriented Programming (1 Month)<br/></h6>

               Delve into TypeScript and Object-Oriented Programming (OOP) fundamentals over one month, covering principles such as classes, 
               interfaces, ambiance, type descriptors, and destructive types. Apply your learning in a practical project, 
               incorporating OOP principles like Solid Principles for robust software design. 
               This intensive curriculum ensures a strong grasp of TypeScript and OOP concepts in a hands-on context.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Angular (1 Month)<br/></h6>

               Master Angular essentials like CLI, modules, components, and data binding, covering topics such as providers, pipes, 
               directives, converters, and NgModel two-way binding. Dive into advanced features including forms, routing, custom pipes and 
               directives, hierarchical components, and 
               communication using EventEmitter and reactive programming, integrating HttpClient, animations, and materials in practical projects.<br/>

               

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>ReactJS (1 Month)<br/></h6>

               Explore ReactJS, web components, and nested components, delving into state management, data binding, and event binding. 
               Tackle Event-Driven programming, implement Redux and Flux, 
               and work on a substantial project with diverse use cases, solidifying your skills in practical scenarios.<br/>
               </Col>
               <Col>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Node,Express and One Advanced Framework (1 Month)<br/></h6>

               Dive into TCP/IP server and client concepts, along with HTTP server functionalities, understanding requests, responses, 
               and middleware. 
               Explore practical applications of these concepts, gaining hands-on experience in building robust server-client interactions.<br/>
               <br/>
               Master Node.js fundamentals including REST API, Mongoose, and server-side validations. Explore NPM, buffer operations, streaming, and various modules like File System, Globals, and Utility. Learn asynchronous programming with callbacks, sync/async functions, promises, and delve into security aspects such as authentication, authorization, JWT, and encryption. Extend your skills to database operations with Mongoose, MySQL, SQL, and Sequelize, and understand server deployment on platforms like Nginx, Apache, Heroku, AWS, with insights into cloud computing, scaling, and packaging in availability zones and regions. Gain practical experience in creating, reading, updating, and deleting operations and user management.<br/>


                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>MongoDB and MySQL (1 month)<br/></h6>

                Explore MongoDB and MySQL databases, covering documents, collections, and database operations. Learn JSON and BSON formats, 
                mastering operations like insert, save, update, delete, find, and aggregation pipelines. Dive into advanced topics such as big 
                data operations, map-reduce, indexing, replication, sharding, and essential functions like backup, drop, remove, and truncate. 
                Enhance your skills in sorting, grouping, wrangling, unwinding, and data loading, with a focus on unique features like capped 
                collections and session-managed collections. Delve into database relationships through joins, transactions, inner joins, DbRef, 
                and multi-collection aggregations, covering both single document and multi-document transactions.<br/>


               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

   
    </section>
    </div>
  );
};

export default MearnStc;
