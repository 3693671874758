import React from "react";

import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import TestAutoImg from '../../assests/images/tae.png';



const TestAutomation = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section>
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal', marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          TEST AUTOMATION TRAINING 
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 7 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 15th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={TestAutoImg} alt="Test Automation" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is Test Automation? </h3>

            <p style={{fontStyle:'normal'}}>
            Test automation is a process that aims to streamline and enhance testing efficiency by automating the execution of tests. Manual testing, being time-consuming and error-prone, is replaced by a suite of automated tests that cover not only the UI layer but also extend to unit and integration levels. This approach accelerates the testing cycle, reduces human errors, and allows for comprehensive validation of software functionality.
            
            Java and Python-based test automation tools are utilized to automate Selenium, unit tests, integration tests, and acceptance tests. These tests are performed using cloud computing practices.
            <br/>
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>


                
                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Web Design Syllabus(1 Month)<br/></h6>

                Learn web design essentials with HTML, CSS3, and Bootstrap, then delve into interactive elements with JavaScript and advanced algorithms for a month, honing skills through practical projects. This comprehensive curriculum equips you with key technologies for modern web development.
                <br/>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Python, Java and Javascript Programming (3 Months)<br/></h6>

             Comprehensive Python programming course covering foundational and advanced features such as Django web development, 
               Seaborn/Matplotlib for visualization, and Numpy/Pandas for data reshaping.<br/>

               <br/>
               Achieve proficiency in Java programming, encompassing advanced features such as Spring Boot, JPA, and REST. The course also covers essential aspects like Spring Security, Spring Data, and Spring MVC, providing a comprehensive understanding of both fundamental and sophisticated Java development techniques. Gain expertise in building robust and secure applications, making you well-versed in the intricacies of Spring framework and associated technologies.
               <br/>
               <br/>
               JavaScript is a versatile programming language widely used for web development. It enables interactive and dynamic web pages through DOM manipulation and event handling. With its asynchronous nature, JavaScript facilitates responsive and seamless user experiences. Its core functionalities include variables, loops, functions, and conditional statements, making it an essential tool for both frontend and backend development.
               <br/>

              

               

              

              
               </Col>
               <Col>
               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Test automaton Concepts and Programming (2 Months)<br/></h6>

Test automation involves implementing practices to efficiently identify and rectify software bugs. It encompasses comprehensive testing strategies, including finding and fixing errors, reading and understanding software, and thorough exploration of software functionalities. Through automated scripts and tools, testing processes are streamlined, ensuring the reliability and quality of software products.
<br/>

<h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Building tests Automated (1 Month)<br/></h6>

Automating tests involves creating a robust testing framework. This includes UI level tests utilizing Selenium for web interfaces, Integration tests employing Citrus, and Acceptance tests with Gauge. For thorough validation, unit tests are crucial, executed seamlessly in Java, Python, and JavaScript. This comprehensive approach ensures the automation of testing processes across different levels, enhancing the reliability and efficiency of software development.
<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Computing (2 weeks)<br/></h6>

AWS hosting, regions and zones, availability groups, load balancing, Docker, Kubernetes, ESB, S3 bucket, 
AMI security configuration, RDS, MongoDB, etc. Azure computing and resource management. Google Colab. User.<br/>
               



              
               
               <hi> </hi>
               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

    
    


       
    </section>
    </div>
  );
};

export default TestAutomation;
