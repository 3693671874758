import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PyStc from "../../components/py-fullstack/py-fullstack";







const PyStack= () => {
  return (
    <Fragment>
    
      <Header />
     <PyStc/>
           
      <Footer/>
    </Fragment>
    

  );
};

export default PyStack;
