import React from "react";
import "./Aboutex.css";
import { Container, Row, Col } from "reactstrap";
import AboutImg from "../../assests/images/aboutus.png"
import { Link } from 'react-router-dom';


const Aboutex = () => {
  
  return (
    <div>
     
     
        <div className="bod6">
        <Container>

          <section>
        

              
      <div>
    <div style={{marginLeft:'55px'}}>
      <Row>
        <Col md={12} lg={6}>
         
          <div className="hero-info">
          
            <h1 style={{fontFamily:'Oswald, sans-serif',fontWeight:'bold',color:'#0e'}}>
            About Us!
            </h1>
            <p style={{fontFamily:'Exo, sans-serif',fontStyle:'normal',fontWeight:'Normal',maxWidth:'600px'}}>
            <span style={{fontWeight:'bolder',color:'#702963',fontSize:'18px',fontFamily:'Aspekta'}}>ExpertzLab</span>, a distinctive IT finishing school, specializes in training on cutting-edge Gen 4.0 technologies, actively contributing to the up-skill and re-skill movement within the IT industry, serving as a 
            pivotal force in training the extensive technical workforce on the latest Gen 4.0 technologies.
            </p>
            
          </div>

          <div className="w-100" style={{ display: 'flex', alignItems: 'center',justifyContent:'start',maxWidth:'350px',marginTop:'30px' }} >

                    <Link to="/all-courses">
                    <button className="btnn">Our Courses</button>
                    </Link>
                    
                    <Link to="/contact">
                        <button className="btin">Contact US</button>
                     </Link>
                     
                </div>
         
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={AboutImg} alt="hero" style={{width:'90%',marginTop:'30px'}} />
          </div>
        </Col>
      </Row>
    </div>
  </div>



  </section>

  
            </Container>
       </div>

       <div>
        <Container>

<h3 style={{textAlign:'center',fontFamily:'Exo, sans-serif',
fontSize:'40px',fontWeight:'bold',color:'#0e',marginBottom:'50px',marginTop:'100px', padding:'10px',display: 'flex',
justifyContent: 'center',
alignItems: 'center',
margin: 'auto', 
position:'relative',


}} >
  You want the best? We know how to build the best. </h3>

  <h3 style={{fontFamily:'Exo, sans-serif',fontSize:'30px',fontWeight:'bold',color:'blueviolet',marginTop:'50px'}}> Our Key Features:</h3>
  <ul style={{fontSize:'20px',fontFamily:'ABeeZee-Regular, Helvetica'}}>
    <li>Comprehensive programs led by top-tier faculty.</li>
    <li>Strong emphasis on use-case driven training for practical application.</li>
    <li>Successful student placements in preferred corporates.</li>
    <li>High demand from corporates for more students, validating effective training.</li>
    <li>Students on a trajectory towards dream jobs in the IT sector.</li>
    <li>Increasing adoption of technologies necessitates skillset expansion.</li>
    <li>Ongoing disruption in Indian IT calls for swift adaptation to Gen 4.0 technologies.</li>
    <li>Expertzlab, the ideal partner for both corporate recruitment and student career kickstart.</li>
  </ul>
  </Container>
</div>
       
</div>
  );
};

export default Aboutex;
