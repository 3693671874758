import React,{useState,useEffect} from "react";
import "./placements-img.css";
import { Container } from "reactstrap";
import {urlFor,client} from "../../client.js"

const PlaceImg = () => {

  const [placements, setPlacements] = useState([]);

  useEffect(() => {
    const  query = '*[_type == "placements"]';

    client.fetch(query).then((data) => {
     setPlacements(data);
  });
 
          }, []);

  return (


    


    <section className="section-background">
      <Container>
        <p className="tst1"> Our Latest Placements</p>


        {placements.map((placements) => (
        <div className="video-row">
          <div className="video-container">
            <img src={urlFor(placements.image)} width="90%"
             height="250px" alt="Placement"  />
            <p className="label1">{placements.name} </p>
          </div>

        

          </div>
          ))}
          

          

          
      </Container>
    </section>








  













  );
};

export default PlaceImg;
