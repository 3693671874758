import React from "react";

import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import MobImg from '../../assests/images/mobapp.png';



const MobApp = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section>
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal', marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          MOBILE APPLICATION DEVELOPMENT TRAINING 
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 15th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={MobImg} alt="Robotics" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is Mobile Application Development? </h3>

            <p style={{fontStyle:'normal'}}>
            Mobile application development entails creating software applications for mobile devices, with a focus on platforms like Android and iOS. Developers can opt for native development for specific platforms or employ a hybrid model for cross-platform compatibility. This dynamic field involves designing, coding, testing, and deploying applications, catering to diverse user experiences on smartphones and tablets.
            
            <br/>
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>


                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Java, Kotlin and Javascript Programming (3 Months)<br/></h6>

                Mastering advanced Java, Kotlin, and JavaScript is crucial for in-depth Android and iOS application development. This proficiency extends to utilizing advanced features in native languages such as Java (for Android), Kotlin (for Android), and Swift (for iOS). Additionally, a comprehensive understanding of JavaScript is vital for creating feature-rich applications through hybrid development approaches. This expertise ensures developers can harness the full potential of these languages to build sophisticated and high-performance mobile applications for diverse platforms.
                <br/>

               <br/>
               

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Android Native and Hybrid concepts (2 Months)<br/></h6>

             Understanding both Android Native and Hybrid development is key for mobile app programming. Android SDK proficiency, encompassing Activities, Intents, and Services, is crucial for Native development. For Hybrid approaches, knowledge of iOS development and Objective-C programming is essential. This comprehensive skill set allows developers to navigate seamlessly between platforms, leveraging both native and hybrid paradigms for robust and versatile mobile application development.
             <br/>

               


  
               </Col>
               <Col>

               
               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Building Hybrid Applications (1 Month)<br/></h6>
               Building hybrid applications involves mastering frameworks like Flutter and React Native. This skill set enables developers to create versatile and efficient applications that work seamlessly across multiple platforms. With expertise in Flutter and React Native, developers can leverage a single codebase to deploy high-performance apps on both iOS and Android, streamlining the development process and ensuring a consistent user experience.
               <br/>

               

               




<br/>

              
               
               <hi> </hi>
               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

    
    


       
    </section>
    </div>
  );
};

export default MobApp;
