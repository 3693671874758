import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PgDip from "../../components/pg-diploma/pg-diploma";





const PgDiploma= () => {
  return (
    <Fragment>
    
      <Header />
      <PgDip/>
        
      <Footer/>
    </Fragment>
    

  );
};

export default PgDiploma;
