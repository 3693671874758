import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Home from "./pages/home/Home";
import AboutAll from './pages/about/about';
import AllCourse from './pages/all-courses/all-courses';
import AllPlacement from './pages/placement/placements';
import ContactInfo from './pages/contact/contact';
import BigData from './pages/bigdata/bigdata';
import DataSci from './pages/datascience/datascience';
import MearnStack from './pages/mearn-stack/mearnstack';
import PyStack from './pages/python-fullstack/python-fullstack';
import JavaStack from './pages/java-fullstack/java-fullstak';
import RoboticsAi from './pages/robotics/robotics';
import VirtulReal from './pages/virtual-reality/virtual-reality';
import CyberSecu from './pages/cyber-security/cyber-security';
import PgDiploma from './pages/pg-diploma/pg-diploma';
import TestAuto from './pages/test-automation/test-automation';
import MobDev from './pages/mob-app-dev/mobapp';
import AwsCloud from './pages/aws-cloud/asw-cloud';
import TaEt from './pages/taet/taet';
import BlogsPage from './pages/blogs-page/blogs-page';
import SinglePostPage from './pages/SinglePostPage/SinglePostPage';



function App() {


  return( 
    <div>
      <BrowserRouter>
      <Routes>

        <Route index element={<Home />} />
        <Route path='/about' element={<AboutAll/>} />
        <Route path='/all-courses' element={<AllCourse/>} />
        <Route path='/placement' element={<AllPlacement/>}/>
        <Route path='/contact' element={<ContactInfo/>}/>
        <Route path='/all-courses/bigdata' element={<BigData/>}/>
        <Route path='/all-courses/datascience' element={<DataSci/>}/>
        <Route path='/all-courses/mearnstack' element={<MearnStack/>}/>
        <Route path='/all-courses/python-fullstack' element={<PyStack/>}/>
        <Route path='/all-courses/java-fullstack' element={<JavaStack/>}/>
        <Route path='/all-courses/robotics' element={<RoboticsAi/>}/>
        <Route path='/all-courses/virtual-reality' element={<VirtulReal/>}/>
        <Route path='/all-courses/cyber-security' element={<CyberSecu/>}/>
        <Route path='/all-courses/pg-diploma' element={<PgDiploma/>}/>
        <Route path='/all-courses/test-automation' element={<TestAuto/>}/>
        <Route path='/all-courses/all-courses/mobile-development' element={<MobDev/>}/>
        <Route path='/all-courses/aws-cloud' element={<AwsCloud/>}/>
        <Route path='/blogs/:slug' element={<SinglePostPage/>}/>
        <Route path='/blogs' element={<BlogsPage/>}/>
        <Route path='/talent-aquisition-emerging-tools' element={<TaEt/>}/>
       


      </Routes>
      </BrowserRouter>
  </div>
  )}

export default App;
