import React from "react";
import "./about.css";
import { Container} from "reactstrap";



import "./about.css";

const AboutUs = () => {
  
 return (

  <div className="framerr">

  <Container>
   
    
                <div className="text-wrapper">
                  <i style={{
                color:'gold',
                verticalAlign:'middle',
                marginRight:'2px'
                }} class='bx bx-info-circle'>
                  </i>About us</div>
                <br/>
            
       
          <p className="about__title" >
           By developers, for developers. <br/> Crafting innovation through passion.<br/>
            </p>
            

            <p className="about__desc" >
            Our mission is to provide assured placements through our 100% job-oriented programs, guided by our 15+ years experienced trainers.
            <br/>
            
      
            </p>

           

       
     
      
              <a href="/about"  className="read-more">Read More <i style={{color:'#0077ff',fontSize:'25px',verticalAlign: 'middle',marginLeft:'-8px' }} class='bx bx-chevrons-right'></i></a>
            
  
    </Container>
    </div>
  );
};

export default AboutUs;


