import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import './SinglePost.css'
import BlockContent from '@sanity/block-content-to-react';
import { Container } from "reactstrap";
import Newsletter from '../Newsletter/Newsletter';
import { client } from '../../client';


const SinglePost = () => {

    const [singlePost,setSinglePost] = useState([])
    const [isLoading,setisLoading] =useState([true])
    const {slug} = useParams()

        useEffect (() =>{
            client.fetch(
                `*[slug.current == "${slug}" ] {
               title,
               titledef,
               blogtitle,
               blogdes,
               content,
               author,
               date,
               category,
               comments,
               slug,
               mainImage {
                       asset -> {
                           _id,
                           url
                       },
                       alt
                       }
               }`
           ).then((data) => setSinglePost(data[0]))
           setisLoading(false)
          
        }, [slug])
    


    return (
    <div>
        
        <div className='background1'>
            {isLoading ?<h1>Loading...</h1> :
            <Container>
            <section > 

                <div >
                    <h1 style={{textAlign:'center',fontWeight:'bold',marginBottom:'20px'}}>{singlePost.blogtitle} </h1>
                    <div style={{ display: 'flex', justifyContent: 'center',flexDirection: 'column', alignItems: 'center',marginBottom:'40px' }}>

                        {singlePost.mainImage && singlePost.mainImage.asset && (
                        <img style={{padding:'10px',width:'70%',borderRadius:'50px',marginBottom:'10px' }}
                         src={singlePost.mainImage.asset.url} alt={singlePost.title} />
                         )}
                        <h4 style={{width:'70%',textAlign:'start',marginTop:'60px'}}>{singlePost.blogdes}</h4>
                     </div>          
                </div>

                <div style={{display:'flex'}}>

                     <div className='blog-content'>

                     <BlockContent blocks={singlePost.content} />



                     
                   </div>
                
                            <div className="side-bar">
                            <div>
                            <i className='fa fa-user' style={{ marginRight: '8px' }}> {singlePost.author}</i>
                           </div>

                          <div>
                           <i className='fa fa-calendar' style={{ marginRight: '8px' }}> {singlePost.date}</i>
                          </div>
                          <div>
                           <i className='fa fa-list' style={{ marginRight: '8px' }}> {singlePost.category} </i>
                          </div>

                          <div>
                           <i className='fa fa-comments-o' style={{ marginRight: '8px' }}> {singlePost.comments} </i>
                          </div>

                          

                    </div>

                </div>

            </section>

        </Container>
        }
        </div>
        <Newsletter/>
    </div>
  )
}

export default SinglePost;



