import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MearnStc from "../../components/Mearn-stack/Mearn-stack";






const MearnStack= () => {
  return (
    <Fragment>
    
      <Header />
      <MearnStc/>
           
      <Footer/>
    </Fragment>
    

  );
};

export default MearnStack;
