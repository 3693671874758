import React from "react";
import "./ta-et.css"
import {Row, Col, Container } from "reactstrap";
import { Link } from 'react-router-dom';
import TaetImg from "../../assests/images/talent.png"
import TaetCard from "./taetCard";




const taetData = [
    {
      id: "01",
      title: "TAET Portfolio",
      description:'Expertzlab specializes in offering tailored training and IT staffing solutions within the IT industry as part of the TAET portfolio.'
    },
  
    {
        id: "02",
        title: "Workforce Enablement in Gen 4.0 Technologies",
        description:'TAET is dedicated to workforce enablement, with a specific focus on Gen 4.0 technologies.'
      },
    
      {
        id: "03",
        title: "Comprehensive Training Curriculum",
        description:'Expertzlab provides a highly comprehensive training curriculum, benchmarked with global skilling programs.'
      },
    
      {
        id: "04",
        title: "Role-Driven Approach",
        description:'Programs focus on role-driven skills rather than being confined to one technology platform.'
      },
    
      {
        id: "05",
        title: "Immediate Productivity",
        description:'The training ensures that developers, programmers, and architects can contribute effectively from day one, reducing the need for additional mentoring or ILP programs.'
      },
    
      {
        id: "06",
        title: "Customization for Organizations",
        description:'TAET programs are customizable to meet the specific technology training needs of organizations.'
      },
    
      {
        id: "07",
        title: "Consultative Approach",
        description: "Expertzlab's consulting team collaborates with the organization's PMO or Technology team to define the syllabus based on specific requirements."
      },
    
      {
        id: "08",
        title: "Flexible Training Selection",
        description:' Trainees can be selected either by Expertzlab or by the organization, depending on the training scenario, including greenfield events or campus recruitment programs.'
      },
    
      {
        id: "09",
        title: "Use-Case and Scenario-Based Training",
        description:'Training involves real-world use-cases, algorithms, and scenarios aligned with the business needs of the organization.'
      },
    
      {
        id: "10",
        title: "SCARF Reports for Fitment Evaluation",
        description:'Trainees are equipped with SCARF reports (behavioral reports) that provide insights into team fitment scenarios for these recruits.'
      },
    
      {
        id: "11",
        title: "Language and Soft Skills Orientation",
        description:'Trainees may receive orientation on language and soft skills, especially if there is an element of onsite client interaction.'
      },
    
      {
        id: "12",
        title: "World-Class Talent Access",
        description:'TAET program is recognized as one of a kind, offering a world-class and comprehensive approach, ensuring organizations always have access to top-tier talent.'
      },
    
      {
        id: "13",
        title: "Building the Best",
        description:"Expertzlab emphasizes its expertise in building the best talent, aligning with the organization's pursuit of excellence."
      },
    
                          
  ];
  


const ExpertzlabTaet = () => {
  return (

   <div>
    <div className="div-framer">
         <Container>
            <section style={{marginLeft:'55px'}}> 
            <Row>
                <Col>
                <h1
                 style={{textAlign:'start',fontWeight:'bold',marginBottom:'10px'}}>
                    ExpertzLab TAET
                </h1>

                <h4 
                 style={{textAlign:'start'}}>
                    Expertzlab introduces Talent Acquisition on Emerging Technologies!
                </h4>

                <p style={{fontFamily:'Exo, sans-serif',fontStyle:'normal',fontWeight:'Normal',maxWidth:'600px'}}>
                    Expertzlab can facilitate your acquisition of talent in AI-oriented Data Science, Big Data Analytics, 
                    Python, Java, Angular, React.js, and JavaScript, elevating your capabilities and expertise.
                </p>

                <div  style={{ display: 'flex', alignItems: 'start',justifyContent:'start',marginLeft:'0px',marginTop:'30px' }} >

                    <Link to="/all-courses">
                    <button className="btnn">Our Courses</button>
                    </Link>
                    
                    <Link to="/contact">
                        <button className="btin">Contact US</button>
                     </Link>
                     
                </div> 


                
                
                
                
                </Col>
                <img 
                className="taetimg"
                style={{
                  width: '600px',
                  height: '400px',
                  
                  
                }}
                src={TaetImg}
                alt="Talent Acquisition"
              />


            </Row>


           
            </section>
             </Container>

             
     </div>

     
    <h2 className="heading-p" style={{marginTop:'60px',textAlign:'center',
    fontFamily: 'Oswald, sans-serif',
    
    fontStyle: 'normal',
    fontWeight: 500,
   
    }}> Talent Acquisition on Emerging Technologies</h2>

     <div style={{margin:'60px',
     marginTop:'60px',
     paddingLeft:'60px',
     display:'flex',
     overflow:'hidden',
     justifyContent:'center', 
     flexWrap: 'wrap',}}>



      {taetData.map((item) => (  
       
      <div className="taet__data"  lg="4" md="6" sm="6" >
              <TaetCard key={item.id} item ={item} />
            </div>
             ))}



    </div>

   

  

</div>
         

         
 
  );
};

export default ExpertzlabTaet;
