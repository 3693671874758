import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "reactstrap";
import courseImg1 from "../../assests/images/data.png";
import courseImg2 from "../../assests/images/DS AI.png";
import courseImg3 from "../../assests/images/MEA.png";
import courseImg4 from "../../assests/images/python.png"
import courseImg5 from "../../assests/images/java.png"
import "./courses.css";
import CourseCard from "./CourseCard";

const coursesData = [
  {
    id: "01",
    title: "Big Data Analytics Expert",
    duration: "8 Months",
    date: "Starts 15th of Every Month",
    imgUrl: courseImg1,
    moreDetailsLink: "/all-courses/bigdata",
  },

  {
    id: "02",
    title: "Data Science with AI",
    duration: "8 Months" ,
    date: "Starts 15th of Every Month",
    imgUrl: courseImg2,
    moreDetailsLink: "/all-courses/datascience",

  },

  {
    id: "03",
    title: "MEA(R)N Stack Development",
    duration:"8 Months",
    date: "Starts 15th of Every Month",
    imgUrl: courseImg3,
    moreDetailsLink: "/all-courses/mearnstack",

  },

  {
    id: "04",
    title: "Python Fullstack Development",
    duration: '8 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg4,
    moreDetailsLink: "/all-courses/python-fullstack",

  },

  {
    id: "05",
    title: "Java Fullstack Development",
    duration: '9 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg5,
    moreDetailsLink: "/all-courses/java-fullstack",

  },
];

const Courses = () => {
  return (
    <section style={{marginBottom:'120px'}}>
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
            <div className="course__top justify-content-between align-items-center">
              <div className="course__top__left w-100">
                <h2 className="heading-pop">Our Popular Courses </h2>
                <p>
                  
                Our Advanced Software Development Course,
                covering a range of in-demand programming languages,
                development frameworks, and tools, with a strong emphasis on practical, hands-on learning through
                real-world projects. Taught by experienced industry professionals, these Courses equips students with
                industry-relevant skills such as Web development, Data analysis,
                and Data science. Job placement assistance, flexible learning options
                (in-person and online), and a thriving community for networking and collaboration make these Courses the ideal choice for
                those seeking a successful career in software development.
                </p>
              </div>

              <div className="w-75 text-start">
              <Link to="/all-courses">
  <button className="bton">View All</button>
</Link>
              </div>
            </div>
          </Col>
        
          {coursesData.map((item) => (
            
            <Col lg="4" md="6" sm="6" style={{border: '1px solid #ddd',borderRadius:'50px',maxWidth:'400px', margin:'20px',marginTop:'1px',marginBottom:'8px',
            background: 'linear-gradient(90deg, hsla(61, 100%, 39%, .1) 0%, hsla(63, 85%, 30%, .1) 100%)' }}>
              <CourseCard key={item.id} item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Courses;
