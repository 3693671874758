import React from "react";
import { Container, Row, Col } from "reactstrap";
import courseImg1 from "../../assests/images/data.png";
import courseImg2 from "../../assests/images/DS AI.png";
import courseImg3 from "../../assests/images/MEA.png";
import courseImg4 from "../../assests/images/python.png"
import courseImg5 from "../../assests/images/java.png"
import CoursebgImg from "../../assests/images/coursesbg.png"
import "./Course-page.css";
import { Link } from 'react-router-dom';
import CoursePage from "./Course-page";



const coursesData = [
  {
    id: "01",
    title: "Big Data Analytics Expert",
    duration: "8 Months",
    date: "Starts 15th of Every Month",
    imgUrl: courseImg1,
    moreDetailsLink: "/all-courses/bigdata ",
  },

  {
    id: "02",
    title: "Data Science with AI",
    duration: "9 Months" ,
    date: "Starts 10th of Every Month",
    imgUrl: courseImg2,
    moreDetailsLink: "/all-courses/datascience ",
  },

  {
    id: "03",
    title: "MEA(R)N Stack Development",
    duration:"8 Months",
    date: "Starts 15th of Every Month",
    imgUrl: courseImg3,
    moreDetailsLink: "/all-courses/mearnstack",
  },

  {
    id: "04",
    title: "Python Fullstack Development",
    duration: '8 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg4,
    moreDetailsLink: "/all-courses/python-fullstack",
  },

  {
    id: "05",
    title: "Java Fullstack Development",
    duration: '8 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg5,
    moreDetailsLink: "/all-courses/java-fullstack",
  },
];

const CourseName = () => {
  return (
    <div>
    
   
      <div style={{backgroundColor:'#fafafa',borderRadius:'50px 90px 50px 50px',marginBottom:'60px'}}>
        <section>
      <Container>
      
      
      
    <div className="wrapper">
      <Row>
        <Col>
         
          <div style={{marginLeft:'55px'}}>
          
            <h1 style={{fontWeight:'bold'}}>
            Check out the best software training courses in Kochi.
            </h1>
            <p style={{maxWidth:'600px'}}>
            Our courses are designed to be Job-oriented and we provide hands-on training with 100% dedicated placement support to help you secure meaningful employment. 
            </p>
              <div className="w-100" style={{ display: 'flex', alignItems: 'start',justifyContent:'start',maxWidth:'350px',marginLeft:'0px',marginTop:'30px' }} >

                    <Link to="/all-courses">
                    <button className="btnn">Our Courses</button>
                    </Link>
                    
                    <Link to="/contact">
                        <button className="btin">Contact US</button>
                     </Link>
                     
                </div>

          </div>
          
         
        </Col>
        <Col md={12} lg={6}>
          <div className="hero-image">
            <img src={CoursebgImg} alt="hero" style={{width:'100%'}} />
          </div>
          
        </Col>
        
      </Row>
    </div>
    
  
  </Container>
  </section>
  </div>



        <Container>

        

       
        <Row>
          <Col lg="12" className="mb-5">
            <div className="course__top d-flex justify-content-between align-items-center">
              <div className="course__top__left ">
                <h2 className="heading-pop">Our Popular Courses</h2>
               
              </div>

            </div>
          </Col>
        


              {coursesData.map((item) => (
           <Col lg="4" md="6" sm="6" style={{border: '1px solid #ddd',borderRadius:'50px',maxWidth:'400px', margin:'20px',marginTop:'1px',marginBottom:'8px',
           background: 'linear-gradient(90deg, hsla(61, 100%, 39%, .1) 0%, hsla(63, 85%, 30%, .1) 100%)'
          

           }}>
           <CoursePage key={item.id} item={item} />
         </Col>








          ))}
        </Row>
        </Container>
  
      
    
    </div>
    

  );
};

export default CourseName;


