import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";


import chooseImg from "../../assests/images/why-choose-us.jpg";
import "./choose-us.css";

import ReactPlayer from "react-player";

const ChooseUs = () => {
  const [showVideo, setShowVideo] = useState(0);
  return (
    <section className="choose-us-section">
      <Container>
        <Row>
          <Col lg="5" md="6">
            <div className="choose__content"  >
              <h2>Why Choose Us?</h2>
              
              <p style={{ fontFamily:'Gill Sans', fontWeight:'lighter',fontStyle:'Normal', fontSize: '18px',marginRight:'20px' }}>
              
              
              • Our trainers are certified professionals working in the industry over 20+ years of expertize.<br/>
            
              • Our courses are categorized in to activity and project labs to get a feel of real project experiance.<br/>
             
              • Our Qualified trainers from industry give you best professional Knowledge.
            
              
              </p>

            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="choose__img">
              {showVideo ? (
                <ReactPlayer
                  url="https://youtu.be/cdK98wdBz6w?si=Ab_3pY1hbVF0WFpG"
                  controls
                  width="100%"
                  height="200%"
                  playing={true}
                />
              ) 
              : (

                <img src={chooseImg} alt="" className="bw1" />
                
              )}

              {!showVideo && (
                <span className="play__icon">
                  <i


                     class="ri-youtube-fill"
                    onClick={() => setShowVideo(!showVideo)}
                  ></i>
                </span>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ChooseUs;
