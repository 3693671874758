import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import JavaStc from "../../components/javastack/javastack";






const JavaStack= () => {
  return (
    <Fragment>
    
      <Header />
      <JavaStc/>
      
           
      <Footer/>
    </Fragment>
    

  );
};

export default JavaStack;
