import React from "react";

import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import JavaImg from '../../assests/images/java.png';



const JavaStc = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section >
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal',marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          JAVA FULL STACK TRAINING
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 15th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={JavaImg} alt="Big Data" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is JAVA FullStack? </h3>

            <p style={{fontStyle:'normal'}}>
            Java Full Stack refers to the comprehensive proficiency in both front-end and back-end development using Java technologies. It involves expertise in Java programming for server-side development, along with proficiency in frameworks like Spring and Hibernate. Additionally, a Java Full Stack developer is well-versed in front-end technologies such as HTML, CSS, and JavaScript, often incorporating modern libraries or frameworks like React or Angular. This skill set allows developers to work on the entire web application stack, from designing user interfaces to implementing server-side logic, ensuring end-to-end functionality and versatility in handling complex projects.


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Web stack and UI building (2 Months)<br/></h6>
             
Mastery of HTML, CSS, JavaScript, and essential web fundamentals, including AJAX, equips developers with the skills for comprehensive 
front-end and back-end web development. This proficiency enables the creation of dynamic and interactive web applications, combining 
sleek user interfaces with Java technologies such as Spring and Hibernate on the server side. With this versatile skill set, developers
 can seamlessly navigate the entire web application stack, ensuring a well-rounded approach to designing, implementing, and optimizing
  web solutions.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Java Programming (2 Months)<br/></h6>

               Achieve proficiency in Java programming, encompassing advanced features such as Spring Boot, JPA, and REST. The course also covers essential aspects like Spring Security, Spring Data, and Spring MVC, providing a comprehensive understanding of both fundamental and sophisticated Java development techniques. Gain expertise in building robust and secure applications, making you well-versed in the intricacies of Spring framework and associated technologies.
               <br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Spring Cloud Micro Services (2 Months)<br/></h6>

               Spring stands as one of the premier web application development frameworks in the Java world. Developing microservices is made easy with Spring Boot, which has been the de facto enterprise stack for years, widely adopted by companies globally. The incorporation of Spring Cloud and frameworks like Hystrix, Ribbon, Consul, and Service Discovery further enhances the capabilities of building scalable and resilient microservices architectures.
               <br/>

               

              
               </Col>
               <Col>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Computing (2 weeks)<br/></h6>

AWS hosting, regions and zones, availability groups, load balancing, Docker, Kubernetes, ESB, S3 bucket, AMI security configuration, RDS, MongoDB, etc. Azure computing and resource management. Google Colab. User.
<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>ReactJS (1 Month)<br/></h6>

               Dive into ReactJS, exploring web components, nested structures, and mastering state and event binding. Learn Event-Driven programming, implement Redux and Flux, and apply these skills to a substantial project for practical experience. Additionally, gain expertise in connecting ReactJS components with backend services using Spring-based REST services.
               <br/>
              <br/>


                


               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

   
    </section>
    </div>
  );
};

export default JavaStc;