import React from "react";
import "./data-analytics.css";
import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import BigDataImg from '../../assests/images/data.png';



const DataAnalytics = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section >
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal',marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          BIG DATA ANALYTICS
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 15th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={BigDataImg} alt="Big Data" />
    </div>
          

          </Col>
        </Row>

        
          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is Big Data Analytics? </h3>

            <p style={{fontStyle:'normal',}}>
              Data-intensive tasks require loading data onto multiple machines in parallel and processing them for various requirements,
               such as pre-processing and indexing. This course focuses on hands-on configuration and programming of big data technologies,
               including Hadoop, Hive, HBase, Pig, Spark, and Sqoop.
               </p>

               </div>



                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',color:'darkblue'}}>Modules:</h4>



               </div>

        
               <Row>
              
              <Col>
               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Python Programming (2 Months)<br/></h6>

             Comprehensive Python programming course covering foundational and advanced features such as Django web development, 
               Seaborn/Matplotlib for visualization, and Numpy/Pandas for data reshaping.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Hadoop based programming (1.5 Months)<br/></h6>

               Yarn components and configuration, MapReduce programs in Python, SQL, data loading, 
               CSV and JSON data processing, file structure, input splits, and combiners.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>PySpark (1 Month)<br/></h6>

               Advanced uses of Spark using Python programming including SQL, Streaming, MLLib.<br/>

               
               </Col>
               <Col>
               
               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Computing (2 weeks)<br/></h6>

AWS hosting, regions and zones, availability groups, load balancing, Docker, Kubernetes, ESB, S3 bucket, 
AMI security configuration, RDS, MongoDB, etc. Azure computing and resource management. Google Colab. User.<br/>

<h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Analytics (1 Month)<br/></h6>

Azure Data factory, Data bricks, Synapse, AWS EMR, SageMaker.<br/>

               </Col>
               </Row>
      

      

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

    
    


       
    </section>
    </div>
  );
};

export default DataAnalytics;
