import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact-form.css';
import { Container } from 'reactstrap';



export const ContactForm = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_phone: '',
    message: '',
  });

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_mcq08sc', 'template_4czx5oh', form.current, '4y5Fh9XhxGteDLDV-')
      .then(
        (result) => {
          console.log(result.text);
          showPrompt('Email sent successfully!');
          setFormData({
            from_name: '',
            from_email: '',
            from_phone: '',
            message: '',
          });
        },
        (error) => {
          console.log(error.text);
          showPrompt('Error sending email. Please try again.');
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const showPrompt = (message) => {
   
    window.alert(message);
  };

  return (

   

    <Container>
      <row>
    <div>
      
    <form ref={form} className="contact-form" onSubmit={sendEmail}>
    <h4 className='heading-pop' style={{  fontWeight:'bold' }}>Leave your number, We will call you...</h4>
    <br/>
    <div className="form-group">
    <input
        className="form-input" 
        type="text"
        name="from_name"
        placeholder='Full Name'
        value={formData.from_name}
        onChange={handleInputChange}
        required
      />
     
      </div>



      <div className="form-group">
      <input
        className="form-input"
        type="email"
        name="from_email"
        placeholder='Email'
        value={formData.from_email}
        onChange={handleInputChange}
        required
      />
      
      
      </div>  
      
      
      <div className="form-group">
      <input
        className="form-input"
        type="tel"
        name="from_phone"
        placeholder='Phone Number'
        value={formData.from_phone}
        onChange={handleInputChange}
        required
      />
     
     
      </div>


      <div className="form-group">
      <textarea
        className="form-textarea"
        name="message"
        placeholder='Your Message'
        value={formData.message}
        onChange={handleInputChange}
      />

      
      </div>

      <input className="form-submit" type="submit" value="Send" />  
    </form>
    </div>

    </row>
   
    </Container>
    
   
   

    
  );
};