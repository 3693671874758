import React from "react";

import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import VrImg from '../../assests/images/vrai.png';



const VirReal = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section >
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal', marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          VIRTUAL REALITY WITH AI TRAINING
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 10th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={VrImg} alt="Robotics" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is Virtual Reality with AI? </h3>

            <p style={{fontStyle:'normal'}}>
            Virtual Reality, enhanced by AI and Data Science, transforms the real world into a 3D simulation. Deep Learning, a pivotal aspect of Data Science, automates machine learning, streamlining preprocessing tasks. Data Analytics, an integral precursor to Data Science, handles the preprocessing of vast datasets, often in tandem with Big Data Analytics. The synergy of these skills is crucial for efficient decision-making by extracting valuable insights from extensive data. Virtual reality experiences, crafted with technologies like Generative Adversarial Networks and Unity 3D, are realized through devices like Oculus Rift/Go, 
            offering immersive simulations of faces, music, and more.
            <br/>
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Python Programming and Web Design (2 Months)<br/></h6>

             Comprehensive Python programming course covering foundational and advanced features such as Django web development, 
               Seaborn/Matplotlib for visualization, and Numpy/Pandas for data reshaping.<br/>

               <br/>
               Learn web design essentials with HTML, CSS3, and Bootstrap, then delve into interactive elements with JavaScript and advanced algorithms for a month, honing skills through practical projects. This comprehensive curriculum equips you with key technologies for modern web development.
               <br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>SciKit Learn (2 Months)<br/></h6>

               A machine learning library that supports regression, logistic regression, classification, clustering, decision trees, 
               random forests, statistical algorithms, and probability distributions.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>TensorFlow and Keras (1 Month)<br/></h6>

               The number one choice for programmers to implement deep learning use cases. Directed acyclic graphs are used to store
                relationships. Keras is a Python-based deep learning extension over TensorFlow and is widely utilized for neural network 
                topology planning, training, and execution. Convolutional Neural Networks (CNN), Recurrent Neural Networks (RNN), 
                Long Short-Term Memory (LSTM), 
               and other advanced artificial neural network use cases can be easily implemented using Keras.<br/>

               

              

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Machine Learning (2 Months)<br/></h6>

               The curriculum covers a comprehensive range of topics in data science and machine learning. 
               From foundational statistical concepts like mean, variance, and distributions, 
               to advanced techniques such as Ridge Regression and SVM, students delve into regression, 
               classification, clustering, and deep learning. Practical skills in SKlearn, feature extraction, and preprocessing methods,
                along with hands-on experience in computer vision and image processing, are emphasized. 
                The course also explores decision trees, ensemble methods, and dimensionality reduction techniques, 
                ensuring a well-rounded understanding of data analysis and modeling.<br/>
               </Col>
               <Col>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Computing (2 weeks)<br/></h6>

AWS hosting, regions and zones, availability groups, load balancing, Docker, Kubernetes, ESB, S3 bucket, 
AMI security configuration, RDS, MongoDB, etc. Azure computing and resource management. Google Colab. User.<br/>
               

                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Deep Learning (1 Month)<br/></h6>

                Deep Learning encompasses key concepts such as Perceptrons, Activation Functions (including Sigmoid and Heavy-sided), and the Perceptron Learning Algorithm for binary classification. Artificial Neural Networks (ANNs) are explored, covering feedforward and feedback ANNs, multilayer perceptrons, and backpropagation. The text delves into practical applications like document classification and handwritten digit classification using frameworks like Keras and Tensorflow, addressing issues like overfitting and optimization with various algorithms. The comprehensive coverage includes topics like tensor operations, kernel methods, and the use of CNNs, RNNs, and LSTMs in projects ranging from sentiment analysis to image classification.
                <br/>

                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Virtual-Reality With AI Project (1 Month)<br/></h6>

                Embark on an immersive journey in the Virtual Reality course through diverse projects. Dive into the realm of Generative AI, exploring applications of LLM, Distributed Learning, and Optimization. Harness the power of GANs, AutoEncoders, and Transformers to create captivating virtual environments. Elevate your skills in Speech-to-Text and Text-to-Speech conversions using techniques like MFCC and Matrix mapping. Develop a Speech Recognition Classifier, fine-tuning with resampled audio and deep learning models. Construct Keras models for wave predictions, visualizing diagnostics and enabling Text-to-Speech capabilities. Take on the challenge of crafting chatbots from scratch, employing Retrieval and Generative models, LSTM, CNN, and advanced techniques like Genism and Wordvec for robust intent classification and dynamic responses. Elevate your Virtual Reality expertise by navigating these cutting-edge projects.<br/>





<br/>

              
               
               <hi> </hi>
               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

    
    


       
    </section>
    </div>
  );
};

export default VirReal;
