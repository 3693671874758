import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DataAnalytics from "../../components/bigdata/data-analytics";




const BigData= () => {
  return (
    <Fragment>
    
      <Header />
      <DataAnalytics/>
     
      
      <Footer/>
    </Fragment>
    

  );
};

export default BigData;
