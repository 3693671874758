import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import RobAi from "../../components/roboai/roboai";






const RoboticsAi= () => {
  return (
    <Fragment>
    
      <Header />
      <RobAi/>
     
      
      <Footer/>
    </Fragment>
    

  );
};

export default RoboticsAi;
