import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ContactUs from "../../components/contactus/contactus";



const ContactInfo= () => {
  return (
    <Fragment>
    
      <Header />
      <ContactUs/>
      
      <Footer/>
    </Fragment>
    

  );
};

export default ContactInfo;
