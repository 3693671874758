import React from "react";
import { Container, Row, Col } from "reactstrap";
import courseImg1 from "../../assests/images/roboai.png";
import courseImg2 from "../../assests/images/vrai.png";
import courseImg3 from "../../assests/images/cyber.png";
import courseImg5 from "../../assests/images/bde.png"
import courseImg6 from "../../assests/images/tae.png"
import courseImg7 from "../../assests/images/mobapp.png"
import courseImg8 from "../../assests/images/aws.png"




import "./Course-page.css";
import CoursePage from "./Course-page";


const coursesData = [
  {
    id: "01",
    title: "Robotics With AI And Data Science",
    duration: "9 Months",
    date: "Starts 15th of Every Month",
    imgUrl: courseImg1,
    moreDetailsLink: "/all-courses/robotics ",
  },

  {
    id: "02",
    title: "Virtual Reality With AI And Data Science",
    duration: "8 Months" ,
    date: "Starts 15th of Every Month",
    imgUrl: courseImg2,
    moreDetailsLink: "/all-courses/virtual-reality ",
  },

  {
    id: "03",
    title: "Cyber Security with AI and Blockchain",
    duration:"8 Months",
    date: "Starts 15th of Every Month",
    imgUrl: courseImg3,
    moreDetailsLink: "/all-courses/cyber-security ",
  },

 

  {
    id: "05",
    title: "PG Diploma in Big Data Engineering",
    duration: '8 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg5,
    moreDetailsLink: "/all-courses/pg-diploma",
  },

  {
    id: "06",
    title: "Test Automation Expert",
    duration: '7 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg6,
    moreDetailsLink: "/all-courses/test-automation",
  },




  {
    id: "07",
    title: "Mobile Application Development Expert",
    duration: '8 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg7,
    moreDetailsLink: "/all-courses/mobile-development ",
  },

  {
    id: "08",
    title: "AWS Cloud Expert",
    duration: '8 Months',
    date: "Starts 15th of Every Month",
    imgUrl: courseImg8,
    moreDetailsLink: "/all-courses/aws-cloud ",
  },


  
];

const OtherName = () => {
  return (
    <section>   
      <Container>
        <Row>
          <Col lg="12" className="mb-5">
            <div className="course__top d-flex justify-content-between align-items-center">
              <div className="course__top__left w-50">
                <h2 className="heading-pop">Other Courses</h2>
               
              </div>

            </div>
          </Col >
          {coursesData.map((item) => (
            <Col  lg="4" md="6" sm="6" style={{border: '1px solid #ddd',borderRadius:'50px',maxWidth:'400px', margin:'20px',marginTop:'1px',marginBottom:'8px',
            background: 'linear-gradient(90deg, hsla(61, 100%, 39%, .1) 0%, hsla(63, 85%, 30%, .1) 100%)'
            }}>
              <CoursePage key={item.id} item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default OtherName;
