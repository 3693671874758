import React from "react";

import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import RoboImg from '../../assests/images/roboai.png';



const RobAi = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section >
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal', marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          ROBOTICS WITH AI TRAINING
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 to 9 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 15th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={RoboImg} alt="Robotics" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is ROBOTICS WITH AI? </h3>

            <p style={{fontStyle:'normal'}}>
            Robotics, when integrated with AI and Data Science, represents a multidisciplinary approach to creating intelligent systems. In the context of Data Science, where Deep Learning is pivotal, Robotics involves developing programs that serve as agents. These programs, embedded in hardware, interact with the environment through sensors and actuators. The fusion of Robotics with AI and Data Science enhances the capabilities of automated systems, facilitating complex decision-making and information processing, often involving large datasets and Big Data Analytics for comprehensive use case completion.
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Python Programming and Web Design (2 Months)<br/></h6>

             Comprehensive Python programming course covering foundational and advanced features such as Django web development, 
               Seaborn/Matplotlib for visualization, and Numpy/Pandas for data reshaping.<br/>

               <br/>
               Learn web design essentials with HTML, CSS3, and Bootstrap, then delve into interactive elements with JavaScript and advanced algorithms for a month, honing skills through practical projects. This comprehensive curriculum equips you with key technologies for modern web development.

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>SciKit Learn (2 Months)<br/></h6>

               A machine learning library that supports regression, logistic regression, classification, clustering, decision trees, 
               random forests, statistical algorithms, and probability distributions.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>TensorFlow and Keras (1 Month)<br/></h6>

               The number one choice for programmers to implement deep learning use cases. Directed acyclic graphs are used to store
                relationships. Keras is a Python-based deep learning extension over TensorFlow and is widely utilized for neural network 
                topology planning, training, and execution. Convolutional Neural Networks (CNN), Recurrent Neural Networks (RNN), 
                Long Short-Term Memory (LSTM), 
               and other advanced artificial neural network use cases can be easily implemented using Keras.<br/>

               

              

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Machine Learning (2 Months)<br/></h6>

               The curriculum covers a comprehensive range of topics in data science and machine learning. 
               From foundational statistical concepts like mean, variance, and distributions, 
               to advanced techniques such as Ridge Regression and SVM, students delve into regression, 
               classification, clustering, and deep learning. Practical skills in SKlearn, feature extraction, and preprocessing methods,
                along with hands-on experience in computer vision and image processing, are emphasized. 
                The course also explores decision trees, ensemble methods, and dimensionality reduction techniques, 
                ensuring a well-rounded understanding of data analysis and modeling.<br/>
               </Col>
               <Col>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Computing (2 weeks)<br/></h6>

AWS hosting, regions and zones, availability groups, load balancing, Docker, Kubernetes, ESB, S3 bucket, 
AMI security configuration, RDS, MongoDB, etc. Azure computing and resource management. Google Colab. User.<br/>
               

                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Deep Learning (1 Month)<br/></h6>

                Deep Learning encompasses key concepts such as Perceptrons, Activation Functions (including Sigmoid and Heavy-sided), and the Perceptron Learning Algorithm for binary classification. Artificial Neural Networks (ANNs) are explored, covering feedforward and feedback ANNs, multilayer perceptrons, and backpropagation. The text delves into practical applications like document classification and handwritten digit classification using frameworks like Keras and Tensorflow, addressing issues like overfitting and optimization with various algorithms. The comprehensive coverage includes topics like tensor operations, kernel methods, and the use of CNNs, RNNs, and LSTMs in projects ranging from sentiment analysis to image classification.
                <br/>

                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Robotics With AI Project (1 Month)<br/></h6>

                In the Speech to Text and Text to Speech conversion project, explore techniques like MFCC and spectrogram mapping to convert speech to matrices. Develop a Speech Recognition Classifier, employing deep learning models by resampling and preprocessing audio waves.
                <br/> 
              
                Another project involves building Chatbots from scratch, covering retrieval and generative-based models, utilizing LSTM for enhanced context understanding. From designing chatbots to preprocessing data with techniques like lemmatization, explore intent classification using CNN, Genism, and Wordvec for a comprehensive understanding of conversational AI.<br/>





<br/>

              
               
               <hi> </hi>
               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
       
       }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

    
    


       
    </section>
    </div>
  );
};

export default RobAi;
