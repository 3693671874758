import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import ExpertzlabTaet from "../../components/ta-et/ta-et";
import Newsletter from "../../components/Newsletter/Newsletter";




const TaEt= () => {
  return (
    <Fragment>
    
      <Header />
      <ExpertzlabTaet/>
      <Newsletter/>
      <Footer/>
     

    </Fragment>
  );
};

export default TaEt;
