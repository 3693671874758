import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = createClient({
  projectId: "r9rgkeg7",
  dataset: "production",
  token:  process.env.REACT_APP_TOKEN,
  apiVersion: '2021-08-31'
  
  
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => {
  return builder.image(source);
}
