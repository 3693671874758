import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import "./hero-section.css";
import regImg from "../../assests/images/regis.png"


import bg1 from "../../assests/images/bg1.jpg";
import bg2 from "../../assests/images/bg2.jpg";
import bg3 from "../../assests/images/bg3.jpg";


const HeroSection = () => {

  const [currentBg, setCurrentBg] = useState(0);
  const backgroundImages = [bg1,bg2,bg3];



  useEffect(() => {

    const interval = setInterval(() => {
      setCurrentBg((currentBg + 1) % backgroundImages.length);
    }, 5000); 

    return () => clearInterval(interval);
  }, [currentBg, backgroundImages.length]);

  const bgStyle = {
    backgroundImage: `url(${backgroundImages[currentBg]}`,
    transition: "background-image 2s ease-in-out",
   
  };



  return (
    
  

<div style={{backgroundColor:'#fafafa',borderRadius:'50px 90px 50px 50px',paddingBottom: '30px' }}>
<section >
  <Container>

  
  
<div className="wrapper">
  <Row>
    <Col md={12} lg={6}>
     
      <div className="hero-main">
      
        <h1 style={{fontFamily:'Oswald, sans-serif',fontWeight:'bolder',color:'#000000'}}>
       Welcome to ExpertzLab
        </h1>
        <h3 style={{fontFamily:'Oswald, sans-serif',fontWeight:'normal',marginBottom:'20px',width:'82%'}}>The best Software Training 
      Institute in Kochi.</h3>
        
        <p style={{fontFamily:' "Exo", sans-serif',fontWeight:'normal',maxWidth:'500px',color:'#000000',}}>
        The best software training institute for Data Science, Artificial Intelligence, Big Data Analytics, Blockchain, Full Stack Python, Java, Angular, React.js, and JavaScript training in Kochi.

        </p>

        <div className="ministry_img" >
<img src={regImg} alt="Registration" />
<br/>

</div>
        
        <div className="w-75" style={{ display: 'flex', alignItems: 'center',justifyContent:'center',maxWidth:'350px',marginLeft:'50px',marginBottom:'20px' }} >

              <Link to="/all-courses">
                <button className="btnn">Our Courses</button>
                </Link>
                
                <Link to="/contact">
                  <button className="btin">Contact US</button>
                  </Link>



              </div>
        
      </div>
     
    </Col>
    <Col md={12} lg={6}>
      
        <section className="bg" style={bgStyle}></section>
     
    </Col>
  </Row>
 
</div>




</Container>
</section>
</div>



  );

};

export default HeroSection;

