import React, { Fragment } from 'react'
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import SinglePost from '../../components/SinglePost/SinglePost';


const SinglePostPage = () => {
  return (
    <Fragment>
        <Header/>
        <SinglePost/>
        <Footer/>

    </Fragment>
  )
}

export default SinglePostPage;
