import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import VirReal from "../../components/virtual-real/virtual-real";






const VirtulReal= () => {
  return (
    <Fragment>
    
      <Header />
      < VirReal/>  
      <Footer/>
    </Fragment>
    

  );
};

export default VirtulReal;
