import Blogs from "../../components/blogs/blogs";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Newsletter from "../../components/Newsletter/Newsletter";
import React, { Fragment }  from 'react'

const BlogsPage = () => {
  return (
    <Fragment>
        <Header/>
        <Blogs/>
        <Newsletter/>
        <Footer/>

    </Fragment>
  )
}

export default BlogsPage;

