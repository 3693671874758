import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import DataScienceai from "../../components/datascienceai/datascienceai";





const DataSci= () => {
  return (
    <Fragment>
    
      <Header />
      <DataScienceai/>     
      <Footer/>
    </Fragment>
    

  );
};

export default DataSci;
