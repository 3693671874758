import React from "react";

import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import PgImg from '../../assests/images/bde.png';



const PgDip = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section>
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal', marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
         PG DIPLOMA IN BIG DATA PROCESSING
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 15th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={PgImg} alt="Robotics" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is Big Data Processing? </h3>

            <p style={{fontStyle:'normal'}}>
            Big Data Processing involves configuring and managing data-intensive applications. The course covers data models, query languages such as Cypher, Spar-QL, and Datalog, and their application in web development with visualizations. Emphasis is placed on storage, retrieval, and aggregation using various NoSQL databases. Thorough discussions on replication, partitioning, transactions, and distribution systems are included, along with in-depth exploration and practice of Hadoop MapReduce and Stream Processing. The training also includes expertise in AWS cloud computing.
            <br/>
            <br/>
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>


                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Python Programming and Web Design (2 Months)<br/></h6>

             Comprehensive Python programming course covering foundational and advanced features such as Django web development, 
               Seaborn/Matplotlib for visualization, and Numpy/Pandas for data reshaping.<br/>

               <br/>
               Learn web design essentials with HTML, CSS3, and Bootstrap, then delve into interactive elements with JavaScript and advanced algorithms for a month, honing skills through practical projects. This comprehensive curriculum equips you with key technologies for modern web development.
               <br/>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Data Storage practices (2 Months)<br/></h6>

             Advanced Data Storage practices encompass intensive processing with both SQL and NoSQL databases. The course delves into data models and explores Cypher Query language. Participants gain hands-on experience in optimizing data-intensive operations for enhanced storage efficiency and retrieval.
             <br/>

               

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Hadoop based Programming (1.5 Months)<br/></h6>
               Hadoop programming involves utilizing Yarn components and configuring them for efficient data processing. MapReduce programs are commonly implemented in Python, SQL is employed for data manipulation, and various data formats like CSV and JSON are processed. Data loading, file structures, input splits, and the use of combiners are essential aspects of Hadoop-based development for distributed data processing.
               <br/>

  
               </Col>
               <Col>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Computing (2 weeks)<br/></h6>

AWS hosting, regions and zones, availability groups, load balancing, Docker, Kubernetes, ESB, S3 bucket, 
AMI security configuration, RDS, MongoDB, etc. Azure computing and resource management. Google Colab. User.<br/>


<h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>PySpark (1 Month)<br/></h6>

               PySpark enables advanced Spark functionalities through Python programming, incorporating SQL for seamless data querying, streaming for real-time data processing, and MLLib for machine learning tasks. This comprehensive approach allows developers to leverage the power of Spark's distributed computing capabilities for diverse and sophisticated data applications.
               <br/>
               

               




<br/>

              
               
               <hi> </hi>
               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

    
    


       
    </section>
    </div>
  );
};

export default PgDip;
