import React, { useEffect, useState } from 'react'
import {Row, Col, Container } from "reactstrap";
import { Link } from 'react-router-dom';
import BlgImg from "../../assests/images/blog.png"
import "./blogs.css"
import {client} from "../../client.js"




const Blogs = () => {
    const [posts,setPosts] = useState([]);

    useEffect(() => {
        client.fetch(
             `*[_type == "blogs" ] {
            title,
            titledef,
            slug,
            mainImage {
                    asset -> {
                        _id,
                        url
                    },
                    alt
                    }
            }`
        ).then((data) => setPosts(data)).catch(console.error);
       
    },[]);
    
  return (
    <div>
        
        <div className="div-framer">
         <Container>
            <section style={{marginLeft:'55px'}}> 
            <Row>
                <Col>
                
                

                <h1
                 style={{textAlign:'start',maxWidth:'600px',fontWeight:'bold'}}>
                     Welcome to Our Insights Hub
                </h1>

                <p style={{maxWidth:'600px'}}>
                Explore our blog to delve into the ever-evolving realms of AI, Data Science, Web Applications, and Software Development. 
                Our experts share valuable insights, experiences, and expert opinions to keep you informed and ahead in the dynamic world of technology.

                </p>

                <div className="w-100" style={{ display: 'flex', alignItems: 'start',justifyContent:'start',maxWidth:'350px',marginLeft:'0px',marginTop:'30px' }} >

                    <Link to="/all-courses">
                    <button className="btnn">Our Courses</button>
                    </Link>
                    
                    <Link to="/contact">
                        <button className="btin">Contact US</button>
                     </Link>
                     
                </div> 


                
                
                
                
                </Col>
                <img style={{width:'500px',height:'350px',marginTop:'20px'}} src={BlgImg} alt="Blogs" />


            </Row>


           
            </section>
             </Container>
             

             
     </div>

     <div className='all__blogs'>   
        <Container>
        <h2 style={{textAlign:'center',margin:'50px'}} className='light'>Check out our Latest Posts.</h2>
        <div className="content-container">
      
      

         {posts.map((post) => (
            <a style={{textDecoration:'none'}} href={`/blogs/${post.slug.current}`}>
            <article key={post.slug.current}>
            <div className="post">
                <img src={post.mainImage.asset.url} alt={post.title}/>
                <h4>{post.title}</h4>
                <p>{post.titledef}</p>
                </div>
            </article>
            </a>
            

         ))}

        </div>
 
        </Container>
        
     </div>
      
    </div>
  )
}

export default Blogs
