import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Aboutex1 from "../../components/About-ex/Aboutex";
import Footer from "../../components/Footer/Footer";
import Newsletter from "../../components/Newsletter/Newsletter";
import AboutSrt from "../../components/about-start/about-start";


const AboutAll = () => {
  return (
    <Fragment>
    
      <Header />
      <Aboutex1 />
      <AboutSrt />
      <Newsletter/>
      <Footer/>
    </Fragment>

  );
};

export default AboutAll;
