import React from "react";

const TaetCard = (props) => {
  const { title, description} = props.item;

  return (
   

      <div >
        <h6 style={{fontWeight:'bold'}} >{title}</h6>
        <hr style={{ width: '100%', margin: '5px 0' }} />

        <div style={{fontFamily:'Exo, sans-serif',paddingBottom:'0px',color: '#1e1e1',lineHeight: '18.2px'}}>
       
            {description} 
            

        </div>
       
        </div>
      
    
  );
};

export default TaetCard;
