import React from "react";

const CoursePage = (props) => {
  const { imgUrl, title, duration,date,moreDetailsLink} = props.item;

  return (
    <div className="single__course__item">
      <div className="course__img">
        <img src={imgUrl} alt="" className="w-100" />
      </div>

      <div className="course__details">
        <h6 className="course__title mb-4">{title}</h6>

        <div className=" d-flex justify-content-between align-items-center">
          <p className="lesson d-flex align-items-center gap-1">
            <i class="fa fa-clock-o"></i> {duration} 
          </p>
        

         
            <a href={moreDetailsLink} className="enroll1 text-decoration-none">
              More Details <i className="fa fa-chevron-right"></i>
            </a>
          


        </div>
        <p className="lesson d-flex align-items-center gap-1">
            <i class="fa fa-calendar"></i> {date} 
          </p>
        </div>
      </div>
    
  );
};

export default CoursePage;


