import React from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import BackToTopButton from './bct'

import "./footer.css";

const footerQuickLinks = [
  {
    display: "Home",
    url: "/",
  },
  {
    display: "About us",
    url: "/about",
  },

  {
    display: "All Courses",
    url: "/all-courses",
  },

  {
    display: "Placements",
    url: "placement",
  },

  {
    display: "Contact us",
    url: "contact",
  },
];

const footerCourseLinks = [
  {
    display: "Big Data Analytics Expert",
    url: "/all-courses/bigdata",
  },
  

  {
    display: "Data Science with AI",
    url: "/all-courses/datascience",
  },

  {
    display: "MEA(R)N Stack Development",
    url: "/all-courses/mearnstack",
  },
  {
    display: "Python Fullstack Development",
    url: "/all-courses/python-fullstack",
  },
  {
    display: "Java Fullstack Development",
    url: "/all-courses/java-fullstack",
  },
];

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
      <Row>

<Col lg="3" md="6" className="txt1">
    

    <div className="follows">
      <p className="txt1">Follow us on social media...</p>
      <span>
        {" "}
        <a  href="https://www.facebook.com/Expertzlabtechnologies">
          <i class="fa fa-facebook-official fa-2x"></i>
        </a>
      </span>
    
      <span>
        {" "}
        <a href="https://www.instagram.com/expertzlab.technologies/">
    
         <i class="fa fa-instagram fa-bounce fa-2x"></i>
     
    
        </a>
      </span>
    
      <span>
        {" "}
        <a href="https://www.linkedin.com/in/sanjay-sabu-745aa4182/">
          <i class="fa fa-linkedin-square fa-2x"></i>
        </a>
      </span>
    
      <span>
        {" "}
        <a href="https://twitter.com/ExpertzlabTech">
          <i class="fa fa-twitter fa-2x"></i>
        </a>
      </span>
    </div>
    </Col>
    


</Row>
        <Row>

        <Col lg="3" md="6" className="ex3">
            <h6 className="ex1">Courses</h6>
            <ListGroup className="link__list">
              {footerCourseLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>


          </Col>

         

          <Col lg="3" md="6" className="ex3">
            <h6 className="ex1">Explore</h6>
            <ListGroup className="link__list">
              {footerQuickLinks.map((item, index) => (
                <ListGroupItem key={index} className="border-0 ps-0 link__item">
                  {" "}
                  <a href={item.url}>{item.display}</a>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>

          

          <Col lg="3" md="6">
            <h5 className="ex1">Institute Hours </h5>

            <p className="ex2">

            Mon:	9:30 AM – 5:30 PM <br/>
            Tue:	9:30 AM – 5:30 PM <br/>
            Wed:	9:30 AM – 5:30 PM <br/>
            Thu:	9:30 AM – 5:30 PM <br/>
            Fri:	9:30 AM – 5:30 PM <br/>
            Sat:	9:30 AM – 5:30 PM <br/>
            Sun:	Closed

              </p>
          </Col>

          <Col lg="3" md="6">
            <h6 className="ex1">Get in Touch</h6>

            <p className="ex2"> Expertzlab Technologies Pvt Ltd,
              2nd Floor, ACT Chambers,
              MKK Nair Rd, Palarivattom,
              Ernakulam, Kerala
            <br/> Phone: (0484)- 4850512 
            <br/>
            Mobile: (91) 7034256363 
            <br/>
            Mobile: (91) 6238743273
            <br/>
            Email: contact@expertzlab.com</p>``
          </Col>

        </Row>
        
        <BackToTopButton />
      </Container>
    </footer>
  );
};

export default Footer;




