import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import CyberSec from "../../components/cyber-security/cyber-security";






const CyberSecu= () => {
  return (
    <Fragment>
    
      <Header />
      <CyberSec/>  
      <Footer/>
    </Fragment>
    

  );
};

export default CyberSecu;
