import React, { useRef} from "react";
import { Container } from "reactstrap";
import {NavLink} from "react-router-dom"
import "./header.css";
import img from "../../assests/images/about-us.png";
import imgNac from "../../assests/images/tcca.png";
import 'font-awesome/css/font-awesome.min.css';

const Header = () => {
  const menuRef = useRef();

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");  

  return (
    <div className="header">
 
    <header  >
      <Container>
      
        <div className="navigation d-flex align-items-center justify-content-between">
          <div className="logo">
            <h2 className=" d-flex align-items-center gap-1">
            <a href="/">
            <img 
            src={img} alt="ExpertslabLogo" style={{ width: "180px" }}  />
            </a>
            </h2>
          </div>

          <div className="nav d-flex align-items-center gap-5">
            <div className="nav__menu" ref={menuRef} onClick={menuToggle}>
              <nav>

              <ul className="nav__list">
 
                <li>
                  <NavLink className='nav__item' to='/'>Home</NavLink>
                </li>
                <li>

                <NavLink className='nav__item' to='/about'>About</NavLink>
                </li>
                <li>

                <NavLink className='nav__item' to='/all-courses'>All Courses</NavLink>
                </li>

                <li>
                   <NavLink className='nav__item' to='/placement'>Placements</NavLink>
                </li>

                
                <li>
                   <NavLink className='nav__item' to='/talent-aquisition-emerging-tools'>ExpertzLab-TAET</NavLink>
                </li>

                <li>
                   <NavLink className='nav__item' to='/blogs'>Blogs</NavLink>
                </li>

                <li>
                   <NavLink className='nav__item' to='/contact'>Contact Us</NavLink>
                </li>


              </ul>
              </nav>
            </div>

            <div className="nav__right">
              <a className="logo" href="https://tccaindia.org/">
              <img style={{width:'100%',height:'70px'}} src={imgNac} alt="ExpertslabLogo"  />
               
              </a>
              
            </div>
          </div>

          <div className="mobile__menu">
            <span>
              <i class="ri-menu-line" onClick={menuToggle}></i>
            </span>
          </div>
        </div>
      </Container>
    </header>
    </div>
  
  );
};

export default Header;
