import React from "react";
import "./newsletter.css";
import { Container, Row, Col } from "reactstrap";

const Newsletter = () => {
  return (
    <section>
      <Container className="newsletter">
        <Row>
          <Col lg="12" className="text-center">
          <h2 className="hv1" >Have Any Questions? <br/> 
          <i style={{fontSize:'25px'}} class="fa fa-phone" aria-hidden="true"></i>
           <span className="highlighted" > +91 7034256363</span> 
           <br/> 
           <a href="https://api.whatsapp.com/send?phone=917034256363" class="icon-link">
           <i class="fa fa-whatsapp" aria-hidden="true"></i>
           <span className="highlighted" > +91 7034256363</span> 
           </a>
           </h2>

           
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Newsletter;
