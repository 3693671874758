import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import CourseName from "../../components/Course-page/Course-name";
import Footer from "../../components/Footer/Footer";
import OtherName from "../../components/Course-page/Other-Course";
import Newsletter from "../../components/Newsletter/Newsletter";



const AllCourse = () => {
  return (
    <Fragment>
    
      <Header />
     <CourseName />
     <OtherName/>
     <Newsletter/>  
      <Footer/>
    </Fragment>

  );
};

export default AllCourse;
