import React from "react";
// import "./Mearn-stack.css";
import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import PyImg from '../../assests/images/python.png';



const PyStc = () => {
  
  return (
    <div>
    <section className="bgclr">


    <section >
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal', marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          PYTHON  FULLSTACK TRAINING
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 15th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={PyImg} alt="Big Data" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is Python FullStack? </h3>

            <p style={{fontStyle:'normal'}}>
            Python full stack development refers to the practice of using the Python programming language to build both the frontend (client-side) and backend (server-side) components of a web application. A full-stack developer proficient in Python is capable of working on all aspects of a web project, from designing and implementing the user interface to managing the server-side logic, databases, and server deployment.
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Javascript and Web Design (1 Month)<br/></h6>
             Learn web design essentials with HTML, CSS3, and Bootstrap, then delve into interactive elements with JavaScript and advanced 
             algorithms for a month, honing skills through practical projects. 
             This comprehensive curriculum equips you with key technologies for modern web development.<br/>

              

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Angular (1 Month)<br/></h6>

               Master Angular essentials like CLI, modules, components, and data binding, covering topics such as providers, pipes, 
               directives, converters, and NgModel two-way binding. Dive into advanced features including forms, routing, custom pipes and 
               directives, hierarchical components, and 
               communication using EventEmitter and reactive programming, integrating HttpClient, animations, and materials in practical projects.<br/>

               

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>ReactJS (1 Month)<br/></h6>

               Explore ReactJS, web components, and nested components, delving into state management, data binding, and event binding. 
               Tackle Event-Driven programming, implement Redux and Flux, 
               and work on a substantial project with diverse use cases, solidifying your skills in practical scenarios.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>MongoDB and MySQL (1 month)<br/></h6>

Delve into Explore MongoDB and MySQL databases, covering documents, collections, and database operations. Learn JSON and BSON formats, mastering operations like insert, save, update, delete, find, and aggregation pipelines. Dive into advanced topics such as big data operations, map-reduce, indexing, replication, sharding, and essential functions like backup, drop, remove, and truncate. Enhance your skills in sorting, grouping, wrangling, unwinding, and data loading, with a focus on unique features like capped collections and session-managed collections. Delve into database relationships through joins, transactions, inner joins, DbRef, and multi-collection aggregations, covering both single document and multi-document transactions.<br/>
               </Col>
               <Col>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Basic and Advanced Python  (1 Month)<br/></h6>

               Immerse yourself in a comprehensive Python and Object-Oriented Programming course covering conditional statements, loops, recursion, and library creation. Explore key concepts like encapsulation, generalization, and functional programming, encompassing classes, interfaces, and abstract classes. Dive into data structures such as dictionaries, lists, sets, and tuples, with hands-on experience in sorting, slicing, and map-reduce operations. Gain proficiency in global variables, PIP, virtual environments, and essential libraries like NumPy and Pandas for handling data formats, matrices, images, and videos. This course offers a robust foundation in Python programming, object-oriented principles, and practical applications in a project-based learning environment.<br/>


                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Django (1 month)<br/></h6>

                Django, a free and open-source web framework written in Python, adheres to the model-view-template architectural pattern. It employs the CGI pattern, enabling applications to have versatile sub-modules switchable through URL patterns. By minimizing boilerplate code, Django maximizes code reusability, resulting in highly maintainable and readable applications. This web framework encompasses view templates and a REST layer, further enhancing its versatility and functionality.<br/>

                

               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
       
       }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

   
    </section>
    </div>
  );
};

export default PyStc;
