import React from "react";
import "./about-start.css";
import { Container, Row, Col } from "reactstrap";
import 'boxicons/css/boxicons.min.css';


const AboutSrt = () => {
  

  return (

    <div className="bod7">
       <Container>
      <div style={{border:'solid',padding:'30px',borderRadius:'30px',marginTop:'60px',paddingBottom:'70px'}}>

       



    <div className="about__contet">
        <h2 className="heading-pop" style={{ color:'#4e23d1', fontFamily:'Oswald, sans-serif' }}><br/>Let's get you started...</h2>
        </div>

        <Row>


            <Col>


            <p className="custom-paragraph"> 
             Do you want to talk to our expert faculties? Don't hesitate; please visit our office at Palarivattom. 
            Attend our classes for one week for free. Understand the quality and our commitment in the program. 
            Pay only if you feel comfortable and see if we can make a big positive impact on your career. 
            We have a money-back policy; if you encounter any difficulties and mention them within the first 20 days, 
            we can reimburse your payment within 60 days.
            </p>

            <a href="/contact" className="enroll d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada ' ></i>Contact Now 
 </a>

              
            
         
            
            </Col>

            <Col md={12} lg={6}>


            <div className="image-container"></div>



            </Col>
        </Row>

        
        </div>
        </Container>
</div>




   




  );
};

export default AboutSrt;
