import React, { Fragment } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PlaceMent from "../../components/all-placement/all-placement";
import PlaceImg from "../../components/placements-img/placements-img";
import Newsletter from "../../components/Newsletter/Newsletter";






const AllPlacement = () => {
  return (
    <Fragment>
    
      <Header />
      <PlaceMent/>
      <PlaceImg/>
      <Newsletter/>
      
      
      <Footer/>
    </Fragment>

  );
};

export default AllPlacement;
