import React from "react";
import { Container, Row, Col } from "reactstrap";
import 'boxicons/css/boxicons.min.css';
import "./contactus.css";
import { ContactForm } from "../contact-form/contact-form";


const ContactUs = () => {
  
  
  const handlePayNowClick = () => {
  
    window.location.href = 'https://razorpay.com/payment-button/pl_I925SCTO1EEvvB/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button';
};

  
 
  return (
    <div style={{background:'#fafafa',borderRadius:'50px 90px 50px 50px',marginBottom:'50px'}}>
      <section className="contact-main-bg">
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Lora:wght@600&family=Oswald:wght@300;400&display=swap');
        `}
      </style>
   
      
      <Container>
        <div className="head1">
          <h2 style={{marginLeft:'20px', textAlign:'center' }}>
            Contact us for your training needs in AI-oriented Data Science, Big Data Analytics, 
            Python, Java, Angular, React.js, and JavaScript training.
          </h2>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            <h2 className="heading-pop"  style={{ 
                fontFamily:'Gill Sans',
                fontWeight:'bold',
                fontStyle:'Normal',
                marginRight:'20px',
                textDecoration:'none', 
                color:''
              }}>
                Get in Touch</h2>
                <br/>
            

                <div style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between',maxWidth:'250px',marginLeft:'20px' }}>
                  <i style={{ color: '', fontSize: '30px' }} class='bx bx-phone-call'></i>
                  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', marginRight: '60px' }}>
                    <a href="tel:+917034256363" style={{color:'black',fontWeight:'lighter',textDecoration:'none'}}>+91 7034 256 363</a>
                    <br/>
                    <a href="tel:+916238743273" style={{color:'black',fontWeight:'lighter',textDecoration:'none'}}> +91 6238 743 273</a>
                    <br/>
                    <a href="tel:04844850512" style={{color:'black',fontWeight:'lighter',textDecoration:'none'}}> (0484) 485 0512</a> 
                    <br/> 
                      </p>
                      
                  </div>
                  <br/>

                  <div style={{ display: 'flex', alignItems:'revert-layer',justifyContent:'space-between',maxWidth:'300px',marginLeft:'20px' }}>
                  <i style={{ color: '', fontSize: '30px' }} class='bx bx-envelope'></i>
                  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', marginRight: '60px' }}>
                    <a href="mailto:contact@expertzlab.com" style={{color:'black',textDecoration:'none',fontWeight:'normal'}}>contact@expertzlab.com</a>
                    
                      </p>
                  </div>
                  <br/>

                  <br/>
                  
                  <div style={{ display: 'flex', alignItems:'revert-layer',justifyContent:'space-between',maxWidth:'240px',marginLeft:'20px' }}>
                    <i style={{ color: '', fontSize: '30px' }} class='bx bxl-whatsapp'></i>
                    <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal',  marginRight: '60px' }}>
                      <a href="https://api.whatsapp.com/send?phone=917034256363" style={{color:'black',textDecoration:'none',fontWeight:'normal'}}>+91 7034 256 363</a>
  
                     </p>
                  </div>
                  <br/>

            
              <h2 className="heading-pop"  style={{ 
                fontFamily:'Gill Sans',
                fontWeight:'bold',
                fontStyle:'Normal',
                marginRight:'20px',
                textDecoration:'none', 
                color:'black'
              }}>
                Meet Us</h2>
                <br/>


                <div style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between',maxWidth:'400px',marginLeft:'20px' }}>
                  <i style={{ color: '', fontSize: '40px' }} class='bx bx-map'></i>
                  <p style={{ fontStyle:'normal',fontWeight:'normal',display: 'flex', alignItems: 'center',justifyContent:'space-between',maxWidth:'320px',marginLeft:'20px' }}>

                Expertzlab Technologies Pvt Ltd,<br/>
                2nd Floor, ACT Chambers,<br/>
                MKK Nair Rd, 
                Palarivattom, Ernakulam, Kerala, India, 
                <br/>
                Pincode - 682025
                <br/>

                
                
                </p>
              





                  </div>
                  <iframe
                   title="Expertzlab Location Map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.175839953408!2d76.30190377450855!3d10.002329772995683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d10b13072a9%3A0x5b632ca6a9de9846!2sExpertzlab%2C%20Data%20Science%2C%20Artificial%20Intelligence%2C%20Python%2C%20MEAN%20Stack%2C%20Big%20Data%20Analytics%2C%20Java%2C%20Blockchain%20training%20in%20Kochi!5e0!3m2!1sen!2sin!4v1704883859410!5m2!1sen!2sin"
      width="100%"
      height="250"
      style={{ border: 0,borderRadius:'15px' }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
                  
                



                


</div>
          </Col>
          

          <Col lg="6" md="6">

          <h2 style=
          {{ 
         
          fontWeight:'bold',
          fontFamily:'Gill Sans',

          fontStyle:'Normal', 
          fontSize: '30px',
          // marginRight:'20px',
          textAlign:'left',
          
         }}>
          
          Pay your Monthly / Registration fee:</h2>
          <button className="pay-now-button" onClick={handlePayNowClick}>
                Pay Now
              </button> 
              <p style={{ 
                fontFamily:'Gill Sans',
                fontWeight:'lighter',
                fontStyle:'Normal',
                fontSize: '15px',
                
                textDecoration:'underline' 
              }}>
                <hr></hr>
                <br/>
                </p>


                <p style={{ 
                fontFamily:'Gill Sans',
                fontWeight:'bold',
                fontStyle:'Normal',
                fontSize: '25px',
                marginRight:'25px', 
              }}>
                
                <ContactForm/>
                
              </p>

              
              
                


          </Col>
        </Row>
        </div>
      </Container>


    
      </section>
     <section>
      <container>




      
      <div style={{
      display: 'flex',
      justifyContent: 'center',
    
     
    }}>




      <div  className="yotubeboxstyle">
      
      
      

        <p style=
            {{ 
           
            fontStyle: 'normal',
          fontFamily:'Gill Sans',
             fontSize: '25px',
             fontWeight:'normal',
            textAlign:'center',
 
            }}>
               <a href="https://www.youtube.com/@expertzlabtechnologiespvt.8887" style={{ color: '#1B1B1B', textDecoration: 'none', }}>
                    Check out our YouTube videos
                
             
              <span className="style22" >
                
           
                
               
              <i className='bx bxl-youtube' ></i>
                </span> 
                </a>
                  <br/>

        </p>
      </div>
    </div>
      </container>


</section>


    </div>
  );
};

export default ContactUs;
