import React from "react";
import "./datascienceai.css";
import 'boxicons/css/boxicons.min.css';
import { Container, Row, Col } from "reactstrap";
import DsImg from '../../assests/images/DS AI.png';



const DataScienceai = () => {
  
  return (
    <div>
    <section  className="bgclr">


    <section>
      
      <Container>
        <div className="head1">
          <h1 className="cor-head" style={{ fontWeight:'bold',fontStyle:'Normal',marginRight:'20px', textAlign:'center',marginTop:'-80px' }}>
          DATA SCIENCE WITH AI TRAINING
          </h1>
          <br/>

          <Row>
            <Col lg="5" md="6">
              <div className="leftcol">
            
                <br/>
                <br/>
            



                  <div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-clock-o'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '25px' }}>
    <span style={{ color: 'black' }}>Duration:</span>
    <span style={{ color: '#03045e' }}> 8 Months</span>
  </p>
</div>
<br/>

<div style={{ display: 'flex',  maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '30px' }} className='fa fa-calendar'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '30px' }}>
    <span style={{ color: 'black' }}>Starts On:</span>
    <span style={{ color: '#03045e' }}> 10th of Every Month </span>
  </p>
</div>
<br/>

<div style={{ display: 'flex', maxWidth: '450px' }}>
  <i style={{ color: '', fontSize: '40px' }} className='fa fa-inr'></i>
  <p style={{ fontFamily: '', fontWeight: 'bold', fontStyle: 'Normal', fontSize: '20px', marginLeft: '35px' }}>
    <span style={{ color: 'black' }}>Fees:</span>
    <span style={{ color: '#03045e' }}> ₹70,000 </span>
  </p>
  
</div>


<br/>

<div style={{  maxWidth: '450px' }}>

  <p style={{ fontWeight: 'bold', fontStyle: 'Normal',marginTop:'40px', fontSize: '40px', marginLeft: '50px' }}>
    
    <span style={{ fontFamily:'sans-serif', color: 'black' }}> Want To Join? </span><br/>
   
    <a href="/contact" className="enro d-flex align-items-center gap-1">
            <i class='bx bxs-phone-call bx-tada' ></i>Contact Now 
                
            </a>
  </p>
</div>


</div>
          </Col>
          

          <Col lg="6" md="6">

          
          <div className="img-container">
      <img src={DsImg} alt="Big Data" />
    </div>
          

          </Col>
        </Row>


          

          <div className="datadesc">
            
            <h3 style={{ marginTop: '40px',marginBottom:'10px' }}>What is Data Science? </h3>

            <p style={{fontStyle:'normal'}}>
            Data Science is a fundamental software development skill, with Deep Learning at its core.
             Data Analytics serves as the precursor, involving preprocessing activities. The preprocessed data is typically 
             employed in Machine Learning Modeling. Timely execution of Data Science tasks is crucial, especially when dealing 
             with extensive data for generating significant insights for decision-making. Therefore, Data Science often collaborates
              with Big Data Analytics. These skills are generally required in tandem to successfully complete important use cases. 
             


               </p>

               </div>

               

                
              

               <h4 style={{fontSize:'28px',fontWeight:'bolder',marginTop:'45px',maxWidth:'950px',color:'darkblue'}}>Modules:</h4>
               
               <Row>
              
                <Col>

               

             <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Advanced Python Programming (2 Months)<br/></h6>

             Comprehensive Python programming course covering foundational and advanced features such as Django web development, 
               Seaborn/Matplotlib for visualization, and Numpy/Pandas for data reshaping.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>SciKit Learn (2 Months)<br/></h6>

               A machine learning library that supports regression, logistic regression, classification, clustering, decision trees, 
               random forests, statistical algorithms, and probability distributions.<br/>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>TensorFlow and Keras (1 Month)<br/></h6>

               The number one choice for programmers to implement deep learning use cases. Directed acyclic graphs are used to store
                relationships. Keras is a Python-based deep learning extension over TensorFlow and is widely utilized for neural network 
                topology planning, training, and execution. Convolutional Neural Networks (CNN), Recurrent Neural Networks (RNN), 
                Long Short-Term Memory (LSTM), 
               and other advanced artificial neural network use cases can be easily implemented using Keras.<br/>

               

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Cloud Computing (2 weeks)<br/></h6>

               AWS hosting, regions and zones, availability groups, load balancing, Docker, Kubernetes, ESB, S3 bucket, 
               AMI security configuration, RDS, MongoDB, etc. Azure computing and resource management. Google Colab. User.<br/>
               </Col>
               <Col>

               <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Machine Learning (2 Months)<br/></h6>

               The curriculum covers a comprehensive range of topics in data science and machine learning. 
               From foundational statistical concepts like mean, variance, and distributions, 
               to advanced techniques such as Ridge Regression and SVM, students delve into regression, 
               classification, clustering, and deep learning. Practical skills in SKlearn, feature extraction, and preprocessing methods,
                along with hands-on experience in computer vision and image processing, are emphasized. 
                The course also explores decision trees, ensemble methods, and dimensionality reduction techniques, 
                ensuring a well-rounded understanding of data analysis and modeling.<br/>

                <h6 style={{fontSize:'18px',fontWeight:'bold',marginTop:'20px'}}>Data Science with AI Projects (2 Months)<br/></h6>

                Learn to do exciting projects in data science with AI. Explore turning spoken words into text and text into 
                spoken words using techniques like MFCC, matrix mapping, and spectrograms. Build skills in Speech Recognition,
                 deep learning models, and CNNs for predicting patterns in audio waves. Create intelligent chatbots from the 
                 ground up using retrieval and generative methods, understanding context with LSTM, and using tools like Gensim
                  and Wordvec. This is a chance to gain practical experience in these fascinating areas of data science and 
                  artificial intelligence.





<br/>

              
               
               <hi> </hi>
               </Col>
               </Row>
      

        </div>

        <div style={{marginTop:'50px',
        border:'2px solid',
        borderRadius:'30px',
        background:'#d6ccc2',
        marginBottom:'50px',
        
        }}>

        <h4 style={{
          marginTop:'30px',
        textAlign:'center',
        marginBottom:'30px',
         fontWeight:'bold',
        fontSize:'18px',
        lineHeight:'20px',
        fontFamily:'sans-serif',
        color:'#1B1B1B',
       
       
        }}>Expertzlab's software courses contain all the industry-required content, 
        and each topic is implemented in class with appropriate project use cases. Students will gain extensive experience in writing code, 
        making this training program an easy gateway to an IT job as a Software Developer.</h4>

        </div>
      </Container>


      </section>

    
    


       
    </section>
    </div>
  );
};

export default DataScienceai;
